<template>
  <!--  // TODO: Добавить лоадер-->
  <!--  v-loading="!isDataLoadedProducts"-->
  <div
    class="products-wrapper page"
  >
    <el-card
      v-if="viewType !== 'table'"
      class="box-card box-card-custom box-control box-control-flex"
    >
      <SelectViewType
        :defaultSelectedType="viewType"
        @select="selectViewType"
      />

      <el-button
        v-if="isShowCreate"
        class="el-button-h-40"
        plain
        type="primary"
        @click="wrapperAdd"
      >
        <span>Добавить</span>
      </el-button>
    </el-card>

    <v-expansion-panels
      v-if="viewType === 'categories'"
      v-model="openedPanel"
    >
      <v-expansion-panel
        v-for="item in getDataCategories"
        :key="item.id"
        @click="loadProductsById(item.id)"
      >
        <v-expansion-panel-header>
          {{ item.title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <data-table
            v-if="isShowDataTable"
            :data="renderData"
            hideBoxControl
          />
          <span v-if="isShowNoResultText">Нет данных</span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <data-table
      v-if="viewType === 'table'"
      :data="renderData"
    >
      <template v-slot:buttonBlock>
        <SelectViewType
          :defaultSelectedType="viewType"
          @select="selectViewType"
        />
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import SelectViewType from '@/components/products/selectViewType'
import DataTable from '@/components/dataTable'

import isInteger from 'lodash/isInteger'

import page from '@/mixins/page'
import tableMethods from '@/mixins/tableMethods'

export default {
  name: 'Products',
  data () {
    return {
      viewType: 'table',
      loadedProductsData: [],
      openedPanel: null
    }
  },
  components: {
    DataTable,
    SelectViewType
  },
  mixins: [page, tableMethods],
  computed: {
    ...mapGetters('products', { getDataProducts: 'getData', isDataLoadedProducts: 'getIsDataLoaded' }),
    ...mapGetters('categories', { getDataCategories: 'getData' }),
    renderData () {
      const data = this.viewType === 'table' ? this.getDataProducts : this.loadedProductsData

      return data.reduce((products, product) => {
        const title = Number(product.salePrice) ? `Скидка - ${product.title}` : product.title

        products.push({
          ...product,
          title
        })

        return products
      }, [])
    },
    isShowDataTable () {
      return this.renderData.length && this.isDataLoadedProducts
    },
    isShowNoResultText () {
      return this.isDataLoadedProducts && !this.renderData.length
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('products', {
      loadDataProducts: 'loadData',
      getDataByCategoriesId: 'getDataByCategoriesId'
    }),
    ...mapActions('categories', { loadDataCategories: 'loadData' }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataProducts()
      await this.loadDataCategories()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    selectViewType (type) {
      this.viewType = type
    },
    async loadProductsById (category) {
      this.loadedProductsData = []

      await this.$nextTick()

      if (!isInteger(this.openedPanel)) {
        return
      }

      this.loadedProductsData = await this.getDataByCategoriesId(category) || []
    },
    // TODO: Костыль решает пробелму обновления данных при рендере категорий
    async wrapperAdd () {
      await this.$nextTick()

      this.openedPanel = null
      this.getCreateMethodWithPage()
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />