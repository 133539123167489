<template>
  <label class="main-label">
    <span class="main-label__title">Выберите тип отображения</span>
    <el-select
      v-model="viewType"
      @input="select"
      placeholder="Выберите тип отображения"
    >
      <el-option
        v-for="item in viewTypesList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </label>
</template>

<script>
export default {
  name: 'Products',
  props: {
    defaultSelectedType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      viewType: this.defaultSelectedType || 'table',
      viewTypesList: [
        {
          label: 'Список',
          value: 'table'
        },
        {
          label: 'Категории',
          value: 'categories'
        }
      ]
    }
  },
  methods: {
    select () {
      this.$emit('select', this.viewType)
    }
  }
}
</script>
